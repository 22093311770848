import './App.css';
import admiralDanger from './admiral-danger.png';

function App() {
  return (
      <div className="App">
        <img src={admiralDanger} alt="Admiral The Cuck Danger" width="500px" />
      </div>
  );
}

export default App;
